<template>
  <div class="pa-9">
    <ValidationObserver ref="add_category">
      <h4>Tên Danh Mục</h4>
      <ValidationProvider
          name="title"
          rules="required"
          v-slot="{ errors }"
      >
        <v-text-field
            outlined
            dense
            :name="'title'"
            v-model="category.title"
            :placeholder="'Nhập title'"
        />
        <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
      </ValidationProvider>
      <h4>Upload ảnh bìa</h4>
      <v-file-input
          class="pl-1"
          id="file_input"
          prepend-icon="mdi-camera"
          v-model="image"
          label=""
          accept="image/png, image/jpeg, image/bmp, image/webp"
          @change="onChangeFileUpload"
          @click:clear="clearImage"
      ></v-file-input>
      <div>
        <img v-if="category.url" :src="category.url" width="200px"/>
      </div>
      <span class="validate-error text-danger">{{ imageError }}</span>
      <h4 class="my-6">Mô tả</h4>
      <v-textarea
          v-model="category.description"
          outlined
      ></v-textarea>
    </ValidationObserver>
    <v-row class="d-flex align-center justify-center my-9">
      <v-col cols="4" class="d-flex align-center justify-center text-center">
        <HnrButton color="primary"
                   @hnrClickEvent="saveConfig"
                    :text="save"/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {UPLOAD_CATEGORY_IMAGE,UPDATE_CATEGORY,GET_DETAIL_CATEGORY} from "@/store/category.module";
import {mapGetters} from "vuex";

export default {
  components: {
    CkContent: () => import( "@/components/ck-content"),
    HnrButton: () => import("@/components/elements/hnr-button")
  },
  data() {
    return {
      category: {},
      imageError: "",
      image: {},
      images: [],
      label: "",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: "",
      save : 'Lưu'

    }
  },
  computed: {
    ...mapGetters({
      categoryDetail: "categoryDetail",
    }),
  },
  watch:{
    categoryDetail(newVal){
      this.category = Object.assign({},newVal)
    }
  },
  created() {
    this.$store.dispatch(GET_DETAIL_CATEGORY, this.$route.params.id)
  },
  methods: {
    onChangeFileUpload() {
      if (this.image) {
        this.imageError = "";
        let formData = new FormData();
        formData.append("upload", this.image);
        this.$store
            .dispatch(UPLOAD_CATEGORY_IMAGE, formData)
            .then((data) => {
              if (data.data.status == 1) {
                this.category.url = data.data.data;
                this.$forceUpdate()
              }
              else {
                this.$toasted.error('File chưa đúng định dạng', {
                  position: "top-right",
                  duration: 3000
                })
              }
            })
      }
    },
    clearImage() {
      this.url = "";
      this.category.url = "";
    },
    async saveConfig(){
      let result = await this.$refs.add_category.validate()
      if (result) {
        if (!this.category.description){
          this.$toasted.error('Bạn chưa nhập mô tả', {
            position: "top-right",
            theme: "toasted-primary",
            duration: 3000
          });
          return false;
        }
        if (this.category.url) {
          this.$store
              .dispatch(UPDATE_CATEGORY, this.category)
              .then((data) => {
                if (data.data.status == 1) {
                  this.$toasted.success('Sửa thành công', {
                    position: "top-right",
                    theme: "toasted-primary",
                    duration: 1000
                  });
                  this.$router.push('/category/list')
                }
              })
        }
        else {
          this.$toasted.error('Bạn chưa chọn ảnh bìa', {
            position: "top-right",
            theme: "toasted-primary",
            duration: 1000
          });
        }
      }
      else {
        this.$toasted.error('Bạn chưa nhập đủ thông tin', {
          position: "top-right",
          theme: "toasted-primary",
          duration: 1000
        });
      }
    },
  }
}
</script>
<style lang="scss">
.block-image{
  position: relative;
  width: 200px;
  .icon{
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>